import "./navbar.css";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  let style;
  const handleClick = () => {
    setShow(!show);
  };

  const link = (navi) => {
    navigate(navi);
    handleClick();
  };

  const colorBarra = () => {};

  return (
    <>
      <div className="nav">
        <img
          src="https://i.imgur.com/r3XyYLS.jpg"
          alt=""
          className="img__nav"
          onClick={() => navigate("/")}
        />
        <div className="navbar-menu">
          <ul className="nav-ul">
            <li
              className={
                location.pathname === "/"
                  ? "nav-link color__active"
                  : "nav-link"
              }
              onClick={() => navigate("/")}
            >
              <p className="nav-link-p">Home</p>
            </li>
            <li
              className={
                location.pathname === "/portafolio"
                  ? "nav-link color__active"
                  : "nav-link"
              }
              onClick={() => navigate("/portafolio")}
            >
              <p className="nav-link-p">Portafolio</p>
            </li>
            {/* <li
              className={
                location.pathname === "/noticias"
                  ? "nav-link color__active"
                  : "nav-link"
              }
              onClick={() => navigate("/noticias")}
            >
              <p className="nav-link-p">Noticias</p>
            </li> */}
            <li
              className={
                location.pathname === "/freelance"
                  ? "nav-link color__active"
                  : "nav-link"
              }
              onClick={() => navigate("/freelance")}
            >
              <p className="nav-link-p">Freelance</p>
            </li>
            <li
              className={
                location.pathname === "/videos"
                  ? "nav-link color__active"
                  : "nav-link"
              }
              onClick={() => navigate("/videos")}
            >
              <p className="nav-link-p">Videos</p>
            </li>
          </ul>
        </div>
      </div>

      <div className="nav-mobile" style={{ top: show ? "0px" : "-400px" }}>
        <AiOutlineClose
          className="boton-cerrar-menu"
          onClick={handleClick}
          style={{ display: show ? "block" : "none" }}
        />
        <AiOutlineMenu
          className="boton-abrir-menu"
          onClick={handleClick}
          style={{ display: show ? "none" : "block" }}
        />
        <div
          className="navbar-menu-mobile"
          style={{ top: show ? "0px" : "-50vh" }}
        >
          <ul className="nav-ul-mobile">
            <div className="nav__div">
              <p className="nav-li-mobile" onClick={() => link("/")}>
                Home
              </p>
            </div>
            <div className="nav__div">
              <p className="nav-li-mobile" onClick={() => link("/portafolio")}>
                Portafolio
              </p>
            </div>
            {/* <div className="nav__div">
              <p className="nav-li-mobile" onClick={() => link("/noticias")}>
                Noticias
              </p>
            </div> */}
            <div className="nav__div">
              <p className="nav-li-mobile" onClick={() => link("/freelance")}>
                Freelance
              </p>
            </div>
            <div className="nav__div">
              <p className="nav-li-mobile" onClick={() => link("/videos")}>
                Videos
              </p>
            </div>
            {/* <Link
                style={{ display: show ? "block" : "none" }}
                className="nav-li-mobile"
                onClick={handleClick}
                to="header"
                spy={true}
                smooth={true}
                duration={500}
              >
                Home
              </Link>
            </div>
            <div className="nav__div">
              <Link
                style={{ display: show ? "block" : "none" }}
                className="nav-li-mobile"
                onClick={handleClick}
                to="about-me"
                spy={true}
                smooth={true}
                duration={500}
              >
                Sobre mi
              </Link>
            </div>
            <div className="nav__div">
              <Link
                style={{ display: show ? "block" : "none" }}
                className="nav-li-mobile"
                onClick={handleClick}
                to="portfolio"
                spy={true}
                smooth={true}
                duration={500}
              >
                Portfolio
              </Link>
            </div>
            <div className="nav__div">
              <Link
                style={{ display: show ? "block" : "none" }}
                className="nav-li-mobile"
                onClick={handleClick}
                to="skills"
                spy={true}
                smooth={true}
                duration={500}
              >
                Skills
              </Link> */}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Nav;
