import React from "react";
import './footer.css';
import { AiFillLinkedin, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';

function Footer () {

    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='botones'>
                    <a href='https://www.linkedin.com/in/sebassotelo/' target='_blank'> <AiFillLinkedin className='boton-footer'/></a>
                    <a href='https://www.instagram.com/_sebassotelo/' target='_blank'> <AiOutlineInstagram className='boton-footer'/></a>
                    <a href='https://twitter.com/_sebassotelo' target='_blank'> <AiOutlineTwitter className='boton-footer'/></a>
                </div>

                <p>Creado por <strong>Sebas Sotelo</strong>. 2022</p>
            </div>
        </div>
    )
}

export default Footer;