import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Video from "../../componentes/video/Video";
import "./videos.css";
import ContextGeneral from "../../servicios/contexto";

function Videos() {
  const context = useContext(ContextGeneral);

  const [videos, setVideos] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchDatos = async () => {
    const fetchYoutube = await fetch(
      "https://www.googleapis.com/youtube/v3/search?key=AIzaSyB4BrehJtk5UpVZpFK8aXl4ve2A8sIo6rA&channelId=UCjnn1S2FKvvkicwEuoBlmAQ&part=snippet,id&order=date&maxResults=20&type=video"
    );
    const datos = await fetchYoutube.json();
    setVideos(datos.items);
    setLoader(true);
  };

  useEffect(() => {
    fetchDatos();
    window.scroll(0, 0);
    document.title = "Videos | Sebas Sotelo";
  }, []);

  return (
    <div className="videos">
      {loader ? (
        <>
          <h2 className="videos__h2">Videos de Youtube</h2>

          <div className="videos__container">
            {videos &&
              videos.map((item, i) => {
                return (
                  <Video
                    title={item.snippet.title}
                    fecha={item.snippet.publishedAt}
                    desc={item.snippet.description}
                    url={item.id.videoId}
                  />
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </>
      )}
    </div>
  );
}

export default Videos;
