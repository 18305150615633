import React from "react";
import "./about-me.css";
import Foto from "../../media/foto.png";

function About() {
  return (
    <div className="about">
      <div className="about-container">
        <div className="foto">
          <img src={Foto} alt="" />
        </div>

        <div className="info">
          <h3 className="title">Sobre Mi</h3>
          <div className="separador"></div>
          <p>Hola! Soy Sebas 🙋‍♂️ Full Stack Developer.</p>
          <p>
            Cuento con conocimientos en React.js, Next.js, Node.js, Express, API
            REST, Postman, Firebase, Sass, Bootstrap, Tailwind, Javascript,
            React Hooks, React Router, GIT, Github, NPM, Vercel, Framer Motion.{" "}
          </p>
          <p>
            Soy una persona proactiva y detallista. Me encuentro constantemente
            estudiando de forma autodidacta para ampliar mis conocimientos
            técnicos. Actualmente estoy en búsqueda activa de trabajo en
            relación de dependencia como Full Stack Developer.
          </p>
          <div className="btnes__about">
            <div className="cv scale-up-center">
              <a
                className="aCv"
                href="https://drive.google.com/file/d/1jgHbQDQlJLToev5Yn06qrox_4_TeR6n2/view?usp=sharing"
                target={"_blank"}
                rel="noreferrer"
              >
                <p className="cv__p">Ver CV</p>
              </a>
            </div>
            <div className="cv">
              <a
                className="aCv"
                href="https://www.linkedin.com/in/sebassotelo/"
                target={"_blank"}
                rel="noreferrer"
              >
                <p className="cv__p">Ir a Linkedin</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
