import React from "react";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { onAuthStateChanged } from "firebase/auth";
import ContextGeneral from "../../servicios/contexto";

import "./home.css";
import About from "../../componentes/about-me/about-me";
import Beneficios from "../../componentes/beneficios/Beneficios";
import NoticiaChica from "../../componentes/noticiaChica/noticiaChica";

function Home() {
  const navigate = useNavigate();
  const context = useContext(ContextGeneral);
  const { setEstadoUsuario, inspectorSesion, llamadaDB } =
    useContext(ContextGeneral);

  useEffect(() => {
    llamadaDB();
    setEstadoUsuario(0);
    onAuthStateChanged(context.auth, inspectorSesion);
    window.scroll(0, 0);
    document.title = "Home | Sebas Sotelo";
  }, []);

  return (
    <>
      <div className="home">
        <div className="home__container">
          <div className="title__mobile">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="home__title">
                <h1 className="title__h1">Sebas Sotelo</h1>
                <h2 className="title__h2">Programador Full Stack</h2>
                <p className="title__p">Autodidacta y Freelancer</p>
                <p className="title__p">
                  Tambien hago videos y streams sobre programación
                </p>

                <div className="title__links">
                  <a
                    href="https://www.linkedin.com/in/sebassotelo/"
                    className="title__a"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Ir a Linkedin
                  </a>
                  <p
                    className="title__a title__a__portfolio scale-up-center"
                    onClick={() => navigate("/portafolio")}
                  >
                    Ir a Portafolio
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
          <motion.div
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100 }}
            className="youtube__container"
          >
            {context.loader && (
              <div className="home__youtube">
                <h3 className="title__h3">Ultimo Video</h3>
                <iframe
                  width="280"
                  height="500"
                  src="https://www.youtube.com/embed/wqrVzQFDwtg?si=7pNTEt0emqiS9r72?rel=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
                <p
                  className="ver__mas__video"
                  onClick={() => navigate("/videos")}
                >
                  Ver mas Videos
                </p>
              </div>
            )}
          </motion.div>
        </div>
      </div>

      {/* PORTAFOLIOOO*/}
      <div className="home__beneficios">
        <Beneficios />
      </div>

      <div className="free">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { duration: 1.5 },
          }}
          style={{ width: "100%", display: "grid", justifyItems: "center" }}
        >
          <div className="free__container">
            <div className="free__contenido">
              <div className="free__text">
                <h4>Todavia no tenes tu pagina web?</h4>
                <p>
                  No te preocupes,{" "}
                  <span onClick={() => navigate("/freelance")}>
                    la hago por vos
                  </span>{" "}
                </p>
              </div>
              <div className="free_img">
                <img
                  className="freelance__img"
                  src="https://i.imgur.com/lbuoZOm.png"
                  alt=""
                  onClick={() => navigate("/freelance")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* <div className="noticias">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { duration: 1.5 },
          }}
          style={{ width: "100%", display: "grid", justifyItems: "center" }}
        >
          <h3 className="noticias__h3">Noticias</h3>
          <div className="noticias__container">
            {context.noticias.slice(0, 3).map((item) => {
              return (
                <NoticiaChica
                  id={item.id}
                  proyecto={item.proyecto}
                  update={item.update}
                  desc={item.descripcion}
                  imagen={item.imagen}
                  fecha={item.fecha}
                  categorias={item.categorias}
                />
              );
            })}
          </div>
          <p
            className="noticias__redireccion"
            onClick={() => navigate("/noticias")}
          >
            Ir a Noticias
          </p>
        </motion.div>
      </div> */}
    </>
  );
}

export default Home;
