import React from "react";
import "./freelance.css";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Beneficios from "../../componentes/beneficios/Beneficios";
import Skills from "../../componentes/skills/skills";
function Freelance() {
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Freelance | Sebas Sotelo";
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_on468r9",
        "template_zz4dwqh",
        e.target,
        "D5JrGZdmv6pi5slBm"
      )
      .then(() => toast.success("Mensaje Enviado"))
      .catch((err) => toast.error("Mensaje no Enviado"));

    e.target.formCorreo.value = "";
    e.target.formNombre.value = "";
    e.target.formTitulo.value = "";
    e.target.formDesc.value = "";
  };
  return (
    <div className="freelance">
      <div className="freelance__container">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="freelance__title">
            <h1 className="title__h1">
              Creo tu <span>sitio web</span>{" "}
            </h1>
            <h2 className="title__h2">¿Que proyectos desarrollo?</h2>
            <p className="title__p__free">
              Desarrollo proyectos como Landing Pages, aplicaciones
              autogestionadas, entre otras muchas opciones. Hago todo para
              acomodar tu emprendimiento dentro del mundo digital.
            </p>
          </div>
        </motion.div>
        <img
          className="freelance__img"
          src="https://i.imgur.com/lbuoZOm.png"
          alt=""
        />
      </div>

      <div className="freelance__beneficios">
        <Beneficios />
      </div>

      <div className="freelance__resultados">
        <div className="resultados__container">
          <div>
            <h4 className="resultados__h4">
              Quiero lograr los mejores resultados.
            </h4>
            <p className="resultados__p">
              Quiero demostrar mis capacidades con hechos, por eso me mantengo
              informado constantemente con las ultimas novedades, para acercarle
              esa informacion a mis potenciales clientes.
            </p>
          </div>
        </div>
      </div>

      <div className="freelance__esperamos">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { duration: 1 },
          }}
          style={{ width: "100%", display: "grid", justifyItems: "center" }}
        >
          <h4>Espero que podamos trabajar juntos!</h4>
        </motion.div>
      </div>

      <div className="freelace__form">
        <h3 className="form__title">Cuentame tu idea</h3>

        <form action="" className="form" onSubmit={sendEmail}>
          <p className="form__p">Correo electronico:</p>
          <input
            type="email"
            name="correo"
            id="formCorreo"
            className="form__input"
            placeholder="Correo Electronico"
            required
          />
          <p className="form__p">Nombre:</p>
          <input
            type="text"
            name="nombre"
            id="formNombre"
            className="form__input"
            placeholder="Nombre"
            required
          />
          <p className="form__p">Titulo de la idea:</p>
          <input
            type="text"
            name="titulo"
            id="formTitulo"
            className="form__input"
            placeholder="Titulo de la idea"
            required
            minLength={6}
          />
          <p className="form__p">Descripcion:</p>
          <textarea
            name="descripcion"
            id="formDesc"
            className="form__area"
            placeholder="Descripcion"
            required
            minLength={30}
          ></textarea>
          <button type="submit" className="form__submit">
            Enviar
          </button>
        </form>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default Freelance;
