import React from "react";
import "./portafolio.css";

import Header from "../../componentes/header/header";
import About from "../../componentes/about-me/about-me";
import Portfolio from "../../componentes/portfolio/portfolio";
import Skills from "../../componentes/skills/skills";

import { useEffect } from "react";
function PortafolioView() {
  useEffect(() => {
    window.scroll(0, 0);
    document.title = "Portafolio | Sebas Sotelo";
  }, []);
  return (
    <>
      {" "}
      <section id="header">
        <Header />
      </section>
      <section id="about-me">
        <About />
      </section>
      <section id="skills">
        <Skills />
      </section>
      <section id="portfolio">
        <Portfolio />
      </section>
    </>
  );
}

export default PortafolioView;
