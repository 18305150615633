import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Nav from "./componentes/navbar/navbar";

import Footer from "./componentes/footer/footer";

import Loader from "./componentes/loader/loader";

//RUTAS
import PortafolioView from "./routes/portafolioView/PortafolioView";
import Home from "./routes/home/Home";
import Freelance from "./routes/freelance/Freelance";
import Videos from "./routes/videos/Videos";
import NoticiasView from "./routes/noticiasView/noticiasView";
import Redes from "./componentes/redes/Redes";
import NoRouteView from "./routes/noRouteView/noRouteView";
import Login from "./routes/login/login";

import ContextNoticias from "./servicios/context";

function App() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {}, []);

  return loader ? (
    <ContextNoticias>
      <Loader setLoader={setLoader} />
    </ContextNoticias>
  ) : (
    <BrowserRouter>
      <ContextNoticias>
        <div className="App">
          <section id="navbar">
            <Nav />
          </section>
          <Redes />

          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/portafolio"} element={<PortafolioView />} />
            <Route path={"/freelance"} element={<Freelance />} />
            <Route path={"/videos"} element={<Videos />} />
            <Route path={"/noticias"} element={<NoticiasView />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"*"} element={<NoRouteView />} />
          </Routes>

          <section id="footer">
            <Footer />
          </section>
        </div>
      </ContextNoticias>
    </BrowserRouter>
  );
}

export default App;
