import React from "react";
import "./nuevoItem.css";
import { motion } from "framer-motion";

function NuevoItem({ titulo, desc, img, tecnologias, url }) {
  const array = ["HTML", "CSS", "JavaScript", "React", "Firebase"];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: { duration: 0.5 },
      }}
      style={{ width: "100%", display: "grid", justifyItems: "center" }}
    >
      <div className="item__container">
        <div className="item__img">
          <a href={url} target={"_blank"}>
            <img src={img} alt="" />
          </a>
        </div>
        <div className="item__descripcion">
          <a href={url} target={"_blank"}>
            <h3>{titulo}</h3>
          </a>
          <p>{desc}</p>{" "}
          <a href={url} className="item__button" target={"_blank"}>
            <p className="item__button__p">Visitar</p>
          </a>
          <p className="item__p">Tecnologias: </p>
          <div className="item__tecnologias__container">
            {tecnologias &&
              tecnologias.map((item, i) => {
                return (
                  <div className="item__tecnologia">
                    <p>{item}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default NuevoItem;
